import { serialize } from 'object-to-formdata'
import snakecaseKeys from 'snakecase-keys'
import axiosIns from '@/plugins/axios'

export default {
  getVendibleTags() {
    return axiosIns.get('/waiter/vendible_tags')
  },
  createVendibleTag({ name }) {
    const formData = serialize(snakecaseKeys({
      vendibleTag: { name },
    }))

    return axiosIns.post('/waiter/vendible_tags', formData)
  },
  updateVendibleTag({ id, name }) {
    const formData = serialize(snakecaseKeys({
      vendibleTag: { name },
    }))

    return axiosIns.put(`/waiter/vendible_tags/${id}`, formData)
  },
  deleteVendibleTag(id) {
    return axiosIns.delete(`/waiter/vendible_tags/${id}`)
  },
  updatePositions(idAndPositions) {
    const formData = serialize(snakecaseKeys({
      vendibleTags: idAndPositions,
    }))

    return axiosIns.put('/waiter/vendible_tags/update_positions', formData)
  },
}
